<template>
    <div class="popup-notification-position">
        <div class="popup-notification border shadow">
            <div class="row">
                <div>
                    <span class="signature-popup-close-pos cursor-pointer"
                     @click="$router.push(LOGIN_PATH)" ><strong>X<i class="bi bi-x-lg"></i></strong></span>
                </div>
            </div>
            <div class="row">
                <p class="popup-notification-content">{{
                    Popup_Success_Msg
                  }}</p>
            </div>
            <br />
            <div class="row">
                <div>
                    <button type="button" class="button-mini-warning-cus"
                        @click="$router.push(LOGIN_PATH)">
                      {{ Back }}<div class="arrow"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {LOGIN_PATH} from '../../router/pathes';

export default {
  data() {
    return {
      Popup_Success_Msg: "Un mail pour finaliser votre création de compte vient de vous être envoyé.",
      Back: "Retour",
    };
  },
    methods: {
    },
    created() {
      this.LOGIN_PATH = LOGIN_PATH
    }
}
</script>

<style scoped>
.popup-notification {
  position: fixed;
  top: 40%;
  left: 0;
  right : 0;
  bottom: 0;
  z-index: 99;
  margin : 0 auto;
  width: 270px;
  height: 230px;

  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  border-color: #D7E2FE !important;
}

.popup-notification-content{
  margin : 0 auto;
  padding-top: 40px;
  width:80%;
  margin-top:10px;
  color : #111179;
  font-size: 15px;
}
</style>

