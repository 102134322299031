<template>
  <NoAuthLayout
    :action="auth.getChangePasswordUrl()"
    :submit="submit"
    :Welcome_Msg="Welcome_Msg">
    <input type="email" name="email"
        :placeholder="Placeholder_Email"
        v-model="email"
        @keyup="onChange" @change="onChange"/>
    <br/>
    <br/>
    <input type="hidden" name="connection" :value="auth.getConnectionName()" />

    <button class="button-warning-cus" type="submit"
        :disabled="disabled">
      {{Continue}}<span class="arrow"/>
    </button>
    <br/>
    <br/>
    <a @click.prevent="back">{{Back}}</a>
    <first-connection-popup v-if="dialog"/>
  </NoAuthLayout>
</template>

<script>
import axios from 'axios';
import NoAuthLayout from '../layouts/noauth.layout';
import {LOGIN_PATH} from "../router/pathes";
import FirstConnectionPopup from '../components/Popups/FirstConnectionPopup'
import {clientId} from "../../auth_config.json";


export default {
  name: "FirstConnection",
  components: {
    NoAuthLayout,
    FirstConnectionPopup
  },
  data() {
    return {
      Page_Title: "Première connexion",
      Popup_Success_Msg: "Un mail pour finaliser votre création de compte vient de vous être envoyé.",
      Welcome_Msg: "Saisissez votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
      Continue: "Continuer",
      Back: "Retour",
      Placeholder_Email: "Email",
      disabled: true,
      email: "",
      dialog: false
    };
  },
  methods: {
    back() {
        this.$router.push(LOGIN_PATH);
    },
    onChange() {
        this.disabled = !(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(this.email));
    },
    /**
     * @param {Event} event
     */
    submit(event) {
      axios.post(
        event.target.action,
        {
          client_id: clientId,
          email: this.email,
          connection: this.auth.getConnectionName()
        })
        .then(() => {
          this.dialog = true
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped lang="scss">
a{
    cursor: pointer;
}

[type="email"] { border-radius: 10px; padding: 5px 10px; border: none; width: 250px; }
</style>
